import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Colors } from '@components/styledComponents/base/Colors';


export enum SpinSize {
    DEFAULT = '80px',
    MIN = '40px',
    SUPPER_MIN = '20px',
}

type Props = {
    spinSize?: SpinSize
};

export default function MiniPreloader (props: Props) {
    const {spinSize = SpinSize.DEFAULT} = props;
    return (
        <Container>
            <Spinner size={spinSize}/>
        </Container>
    );
}


const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: white;
  
  display: flex;
  align-items: center;
  justify-content: center;
`;


const Spin = keyframes`
  from {
    transform: rotate(0deg);
  } 
  to {
     transform: rotate(360deg);
   }
`;

const Spinner = styled.div<{size: SpinSize}>`
  width: ${({size}) => size};
  height: ${({size}) => size};

  border: 2px solid #f3f3f3;
  border-top:3px solid ${Colors.EMERALD};
  border-radius: 100%;

  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  margin: auto;
  
  animation: ${Spin} 1s infinite linear;
`;