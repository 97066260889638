import {ReactNode, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import Helper from '@logic/helpers/Helper/Helper';
import { useFocusTrap } from '@logic/hooks/useFocusTrap';

import { mediaBreakpointDown } from '@components/styledComponents/base/functions';
import { Z_INDEX } from '@components/styledComponents/base/vars';
import Portal from "@src/hoc/Portal";

type Props = {
    children: ReactNode,
    isOpen: boolean,
    onClose: () => void,
    haveLoverMenu?: boolean,
    withCloseButton?: boolean,
};

export default function ModalWindow (props: Props) {

    const {children, isOpen, onClose, haveLoverMenu = false, withCloseButton = false} = props;

    const focusTrapRef = useFocusTrap<HTMLDivElement>();

    const handleEsc = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape' && isOpen) {
            onClose();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    useEffect(() => {
        window.addEventListener('keydown', handleEsc);
        const handleScroll = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
        };

        if (isOpen) {
            document.body.addEventListener('scroll', handleScroll);
            document.body.setAttribute('style', 'touch-action:none;');
        }

        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.body.removeAttribute('style');
            document.body.removeEventListener('scroll', handleScroll);
        };
    }, [onClose, isOpen, handleEsc]);

    return (
        // @ts-ignore
        <Portal>
            <Container
                isOpen={isOpen} 
                onClick={(e)=>Helper.isNotChildOfElem(e) && onClose()} haveLoverMenu={haveLoverMenu}
                ref={isOpen ? focusTrapRef : null}>
                {children}                    
            </Container>
        </Portal>
    );
}

const Container = styled.div<{isOpen: boolean, haveLoverMenu: boolean}>`
  z-index: ${Z_INDEX.modal}; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  margin: auto;
  padding: 20px;
  display: grid;
  justify-items: center;
  align-items: center;
  overflow-y: scroll;

  transition: background 0.5s, visibility 0.5s, opacity 0.5s;
  background: ${({isOpen}) => isOpen ? '#00000091' : 'transparent'};
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  opacity: ${({isOpen}) => isOpen ? 1 : 0};

  pointer-events: ${({isOpen}) => isOpen ? 'unset' : 'none'};
  
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  
  &>:first-child {
    transition: top 0.5s;
    top: ${({isOpen}) => isOpen ? '0' : '15px'};;
  }

  ${mediaBreakpointDown(540)} {
    padding: 10px 10px ${({ haveLoverMenu }) => haveLoverMenu ? '80px' : '10px'} 10px;
  };
`;