import IRule from '@logic/forms/validators/rules/IRule';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';

import checkPhone from 'google-libphonenumber';
const phoneUtil = checkPhone.PhoneNumberUtil.getInstance();


export default class MaxLengthRule implements IRule
{
    private readonly maxValue : number;

    constructor (maxValue: number) {
        this.maxValue = maxValue;
    }
    public check (value : any) : boolean
    {
        return value.length <= this.maxValue;
    }

    public message () : RuleMessage
    {
        return {
            ru: `Поле не может содержать больше ${this.maxValue} символов`,
            en: `The field cannot contain more than ${this.maxValue} characters`,
            lt: `Srityje negali būti daugiau kaip ${this.maxValue} simbolių`,
            uz: `Maydon ${this.maxValue} belgidan oshmasligi kerak`
        };
    }
}

