//Hook
import RuleMessage from "@logic/forms/validators/rules/RuleMessage";

import {useAppSelector} from '@store/hooks/hooks';


type Text = {
    [key: string]: RuleMessage;
};

export type Texts = {
    com: Text,
    uz: Text,
    ru: Text,
};

export default function useLanguage (texts: Texts) {
    const {site} = useAppSelector(state => state.generalStatesSlice);
    return texts[site] ?? texts.com;
}
