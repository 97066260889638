import {css,FlattenInterpolation, ThemeProps} from 'styled-components';

import { Colors } from '@components/styledComponents/base/Colors';
import { hover } from '@components/styledComponents/base/functions';

export enum IconPosition {
    RIGHT = 'right',
    LEFT = 'left'
}

export enum ButtonStyle {
    GREEN = 'green',
    BLACK = 'black',
    STROKE_WHITE = 'strokeWhite',
    ROUND_STROKE_WHITE = 'roundStrokeWhite'
}

export const activeButtonStyles: Record<ButtonStyle, FlattenInterpolation<ThemeProps<any>>> = {
    [ButtonStyle.GREEN]: css`
        background-color: ${Colors.NDA};
    `,
    [ButtonStyle.BLACK]: css`
        background-color: ${Colors.GRAY_900};
    `,
    [ButtonStyle.STROKE_WHITE]: css`
        border: 1px solid ${Colors.GRAY_600};
        background-color: ${Colors.GRAY_100};
    `,
    [ButtonStyle.ROUND_STROKE_WHITE]: css`
        border: 1px solid ${Colors.GRAY_200};
        background-color: ${Colors.GRAY_100};
    `,
};

export const buttonStyles: Record<ButtonStyle, FlattenInterpolation<ThemeProps<any>>> = {
    [ButtonStyle.GREEN]: css`
        --pulse-gradient: radial-gradient(ellipse at center, rgba(76, 252, 173, 0.4) 0%,rgba(76, 252, 173, 0.4) 1%,rgba(76, 252, 173, 0.6) 33%,rgba(0,0,0,0) 100%);

        background-color: ${Colors.EMERALD};
        color: ${Colors.WHITE};
        transition: background-color 0.5s;

        ${hover(css`
            ${activeButtonStyles.green};
        `)}

        @media (pointer:coarse) {
            --pulse-gradient: radial-gradient(ellipse at center, rgba(0, 100, 62, 0.4) 0%,rgba(0, 100, 62, 0.4) 1%,rgba(0, 100, 62, 0.6) 33%,rgba(0,0,0,0) 100%);
        }
    `,
    [ButtonStyle.BLACK]: css`
        --pulse-gradient: radial-gradient(ellipse at center, rgba(244, 244, 244, 0.3) 0%,rgba(244, 244, 244, 0.3) 1%,rgba(244, 244, 244, 0.4) 33%,rgba(0,0,0,0) 100%);

        background-color: ${Colors.GRAY_700};
        color: ${Colors.WHITE};
        transition: background-color 0.5s;

        ${hover(css`
            ${activeButtonStyles.black};
        `)}
    `,
    [ButtonStyle.STROKE_WHITE]: css`
        --pulse-gradient: radial-gradient(ellipse at center, rgba(28, 28, 28, 0.3) 0%,rgba(28, 28, 28, 0.3) 1%,rgba(28, 28, 28, 0.4) 33%,rgba(0,0,0,0) 100%);
            
        background-color: ${Colors.WHITE};
        color: ${Colors.GRAY_900};
        border: 1px solid ${Colors.GRAY_400};
        transition: border 0.5s, background-color 0.5s, color 0.5s;

        ${hover(css`
           ${activeButtonStyles.strokeWhite};
        `)}
    `,
    [ButtonStyle.ROUND_STROKE_WHITE]: css`
        background-color: ${Colors.WHITE};
        color: ${Colors.GRAY_900};
        border: 1px solid ${Colors.GRAY_400};
        transition: border 0.5s, background-color 0.5s, color 0.5s;

        ${hover(css`
           ${activeButtonStyles.roundStrokeWhite};
        `)}
    `,
};