import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '@store/hooks/hooks';

import { Colors } from '@components/styledComponents/base/Colors';

import CloseIcon from '@public/icons/baseUI/cross-icons/cross.svg';

interface Props {
    color?: Colors;
    hoverColor?: Colors;
    size?: CloseBtnSize;
    style?: React.CSSProperties;
    disabled?: boolean;
    clickHandler: (args: unknown) => void;
    label?: string;
}

export enum CloseBtnSize {
    XS = '18px',
    S = '20px',
    M = '24px',
    L = '28px',
}

const defaultLabel = {
    ru: `Закрыть`,
    en: `Close`,
    lt: `Uždengti`,
    uz: 'Yopish'
};

export const CloseButton = ({color = Colors.GRAY_500, hoverColor = Colors.GRAY_600, size = CloseBtnSize.S, disabled = false, style = {}, clickHandler, label}: Props) => {

    const { language } = useAppSelector((state) => state.generalStatesSlice);

    return (
        <Button 
            type='button'
            onClick={clickHandler}
            size={size} 
            color={color} 
            hoverColor={hoverColor} 
            disabled={disabled}
            style={style}
            aria-label={label ?? defaultLabel[language]}>
            <CloseIcon />
        </Button>
    );
};

const Button = styled.button<{color: Colors, hoverColor: Colors, size: CloseBtnSize}>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
        fill: ${({ color }) => color};
        transition: fill 0.5s;
    }

    &:hover {
        svg {
            fill: ${({ hoverColor }) => hoverColor};
        }
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &:focus {
        outline: 3px solid ${Colors.GRAY_400};
        outline-offset: 0px;
        &:not(:focus-visible) {
            outline: none;
        }
    }
`;
