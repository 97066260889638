import React, { useRef } from 'react';
import styled, {css, FlattenSimpleInterpolation, keyframes} from 'styled-components';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

import { appearenceByScale, appearenceOpacity } from '@components/styledComponents/base/animationConfigs';
import { Breakpoints } from '@components/styledComponents/base/breakpoints';
import { Colors } from '@components/styledComponents/base/Colors';
import { mediaBreakpointDown } from '@components/styledComponents/base/functions';
import { typography } from '@components/styledComponents/base/typography';

import LoadingIcon from '@public/icons/baseUI/loading/loading.svg';

import { activeButtonStyles, ButtonStyle, buttonStyles, IconPosition } from './buttonStyles';

const PULSE_SIZE = 50;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode,
    styles?: FlattenSimpleInterpolation,
    isLoading?: boolean;
    Icon?: JSX.Element;
    strokeIcon?: boolean;
    fillIcon?: boolean;
    iconPosition?: IconPosition;
    isSmallHeight?: boolean;
    isWide?: boolean;
    isFit?: boolean;
    wideOnBreakPoint?: Breakpoints | number;
    styleType?: ButtonStyle;
}

export default React.forwardRef(function Button (props: Props, ref?: React.ForwardedRef<HTMLButtonElement>) {

    const {
        children,
        isLoading,
        Icon,
        styleType = ButtonStyle.BLACK,
        iconPosition = IconPosition.LEFT,
        fillIcon = true,
        strokeIcon = false,
        onPointerDown,
        disabled,
        ...rest
    } = props;

    const controls = useAnimationControls();
    const pulseRef = useRef<HTMLSpanElement | null>(null);

    const handlePointerDown: React.PointerEventHandler<HTMLButtonElement> = (e) => {
        if(onPointerDown) {
            onPointerDown(e);
        }
        if(!pulseRef.current) return;

        const {clientX, clientY} = e;
        const {top, left} = e.currentTarget.getBoundingClientRect();

        pulseRef.current.style.top = clientY - top - (PULSE_SIZE / 2) + 'px';
        pulseRef.current.style.left = clientX - left - (PULSE_SIZE / 2) + 'px';

        controls.start({
            opacity: [0, 0.3, 0],
            transform: ["scale(0)", "scale(2.5)", "scale(5)"],
            transition: {duration: 1}
        });
    };

    return (
        <ButtonBody
            isLoading={isLoading}
            styleType={styleType}
            fillIcon={fillIcon}
            strokeIcon={strokeIcon}
            disabled={disabled || isLoading}
            {...rest}
            ref={ref}
            onPointerDown={handlePointerDown}
        >

            <Pulse 
                initial={false} 
                ref={pulseRef} 
                animate={controls} />

            <AnimatePresence>
                {isLoading &&
                <ButtonLoader {...appearenceOpacity} $styleType={styleType}>
                    <LoadIconContainer {...appearenceByScale}>
                        <LoadingIcon/>
                    </LoadIconContainer>
                </ButtonLoader>}                
            </AnimatePresence>

            {iconPosition === IconPosition.LEFT && Icon}
            {children}
            {iconPosition === IconPosition.RIGHT && Icon}
        </ButtonBody>
    );
});

const spin = keyframes`
  from{
    transform: rotate(0deg);
  }to{
     transform: rotate(360deg);
   }
`;

const Pulse = styled(motion.span)`
    position: absolute;
    width: ${PULSE_SIZE + 'px'};
    height: ${PULSE_SIZE + 'px'};
    border-radius: 50%;
    background: var(--pulse-gradient);
    display: block;
    z-index: 2;
    opacity: 0;
    transform: scale(0);
`;

const LoadIconContainer = styled(motion.span)`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        flex-shrink: 0;
        fill: currentColor;
        stroke: transparent;
        animation: ${spin} 1s  infinite linear;
    }
`;

const ButtonLoader = styled(motion.span)<{
    $styleType: ButtonStyle;
}>`
    ${({$styleType}) => activeButtonStyles[$styleType]};
    position: absolute;
    z-index: 1;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
`;

const ButtonBody = styled.button<{
    styles?: FlattenSimpleInterpolation,
    isLoading?: boolean,
    isWide?: boolean;
    isFit?: boolean;
    wideOnBreakPoint?: Breakpoints;
    styleType: ButtonStyle;
    strokeIcon: boolean;
    fillIcon: boolean;
    isSmallHeight?: boolean;
}>`
  ${typography.button};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: ${({isWide}) => isWide ? '100%' : 'fit-content'};
  min-width: ${({isFit}) => isFit ? 'fit-content' : '212px'};
  padding: ${({isSmallHeight}) => isSmallHeight ? '8px 16px' : '14px 24px'};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  ${({wideOnBreakPoint}) => wideOnBreakPoint && 
    css`
        ${mediaBreakpointDown(wideOnBreakPoint)} {
            width: 100%;
        }
    ` };

  &:disabled {
    pointer-events: none;
    background-color: ${Colors.GRAY_400};
    color: ${Colors.WHITE};

    svg {
        filter: grayscale(100%);
    }
  }

    &:focus {
        outline-color: 3px solid ${Colors.GRAY_400};
        outline-offset: 0px;
        &:not(:focus-visible) {
            outline: none;
        }
    }

  ${({styleType}) => buttonStyles[styleType]};
  ${({isLoading, styleType}) => isLoading && activeButtonStyles[styleType]};

    svg {
        fill: ${({fillIcon}) => fillIcon && 'currentColor'};
        stroke: ${({strokeIcon}) => strokeIcon && 'currentColor'};
        flex-shrink: 0;
        width: 18px;
        height: 18px;
    }

  ${mediaBreakpointDown(Breakpoints.Large)} {
    padding: ${({isSmallHeight}) => isSmallHeight ? '8px 16px' : '12px 20px'};
    min-width: ${({isFit}) => isFit ? 'fit-content' : '180px'};
  }

  ${mediaBreakpointDown(Breakpoints.Medium)} {
    min-width: ${({isFit}) => isFit ? 'fit-content' : '150px'};
    padding: ${({isSmallHeight}) => isSmallHeight ? '8px 16px' : '10px 20px'};
    min-width: 150px;

    svg {
        width: 16px;
        height: 16px;
    }
  }

  ${mediaBreakpointDown(Breakpoints.Tablet)} {
    padding: 8px 12px;
    min-width: ${({isFit}) => isFit ? 'fit-content' : '146px'};
    gap: 8px;

    svg {
        width: 14px;
        height: 14px;
    }
  }

  ${mediaBreakpointDown(Breakpoints.xMobile)} {
    min-width: ${({isFit}) => isFit ? 'fit-content' : '132px'};

    svg {
        width: 12px;
        height: 12px;
    }
  }

  ${props => props.styles};
`;