const labels: {
    [key: string]: {
        ru: string;
        en: string;
        lt: string;
        uz: string;
    };
} = {
    copy: {
        ru: `Скопировано`,
        en: `Copied`,
        lt: `Nukopijuota`,
        uz: `Ko'chirilgan`
    },
    windowClose: {
        ru: `Закрыть окно`,
        en: `Close the window`,
        lt: `Uždarykite langą`,
        uz: `Oynani yoping`
    },
    spoilerOpen: {
        ru: `Открыть спойлер`,
        en: `Open the spoiler`,
        lt: `Atidaryti spoilerį`,
        uz: `Spoylerni oching`
    },
    spoilerClose: {
        ru: `Закрыть спойлер`,
        en: `Close the spoiler`,
        lt: `Uždaryti spoilerį`,
        uz: `Spoylerni yoping`
    },
    menuOpen: {
        ru: `Открыть меню`,
        en: `Open the menu`,
        lt: `Atverti meniu`,
        uz: `Menyuni oching`
    },
    menuClose: {
        ru: `Закрыть меню`,
        en: `Close the menu`,
        lt: `Uždaryti meniu`,
        uz: `Menyuni yoping`
    },
    mainLink: {
        ru: `Ссылка на главную страницу`,
        en: `Close the menu`,
        lt: `Uždaryti meniu`,
        uz: `Menyuni yoping`
    },
    selectOpen: {
        ru: `Открыть выпадающий список`,
        en: `Open the drop-down list`,
        lt: `Atverti išskleidžiamąjį sąrašą`,
        uz: `Ochiladigan ro'yxatni oching`
    },
    selectClose: {
        ru: `Закрыть выпадающий список`,
        en: `Close the drop-down list`,
        lt: `Uždaryti išskleidžiamąjį sąrašą`,
        uz: `Ochiladigan ro'yxatni yoping`
    },
    phoneCodesOpen: {
        ru: `Открыть список телефонных кодов`,
        en: `Open the list of phone codes`,
        lt: `Atidaryti telefono kodų sąrašą`,
        uz: `Telefon kodlari ro'yxatini oching`
    },
    phoneCodesClose: {
        ru: `Закрыть список телефонных кодов`,
        en: `Close the list of phone codes`,
        lt: `Uždaryti telefono kodų sąrašą`,
        uz: `Telefon kodlari ro'yxatini yoping`
    },
    logo: {
        ru: `логотип PayAssistant`,
        en: `PayAssistant logo`,
        lt: `logotipas PayAssistant`,
        uz: `PayAssistant logotipi`
    },
    flag: {
        ru: `флаг страны`,
        en: `country flag`,
        lt: `šalies vėliava`,
        uz: `mamlakat bayrog'i`
    },
    selectAllOptions: {
        ru: `Все`,
        en: `All`,
        lt: `Visi`,
        uz: `Hammasi`,
    },
    imgAlt: {
        ru: `Фоновое изображение`,
        en: `Background image`,
        lt: `Fono paveikslėlis`,
        uz: `Fon tasviri`,
    }
};


export default labels;


