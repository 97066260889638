import React from 'react';
import styled from 'styled-components';

import { PayAssistantError } from '@logic/service/API/error/PayAssistantError';

import {useAppSelector} from '@store/hooks/hooks';

import { Text } from '@components/common/texts/typography/Typography';
import { Colors } from '@components/styledComponents/base/Colors';

type Props = {
    error: PayAssistantError | null,
    except?: Array<string>
};


export default function AnyError (props: Props) {

    const {error, except = []} = props;
    const {language} = useAppSelector(state => state.generalStatesSlice);

    const getError = () => {
        if(!error) {
            return '';
        }
        const message = error.getAnyErrorExcept(except);

        if(message) {
            return message[language];
        }
        return '';
    };

    if(getError() === '') {
        return null;
    }

    return (
        <Container>
            <ErrorIcon/>
            <Text font='bodySmall' color={Colors.DANGER} >
                {getError()}
            </Text>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ErrorIcon = styled.div`
  background: url("/landing/modalAuth/alert.svg") bottom no-repeat;
  background-size: contain;
  height: 16px;
  min-width: 16px;
`;